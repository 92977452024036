"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComplexModal = void 0;
const modal_1 = require("./modal");
class ComplexModal extends modal_1.Modal {
    getTemplate() {
        const wrapper = this.generateOverlay();
        const modal = this.generateModal();
        modal.classList.add("ComplexModal");
        wrapper.appendChild(modal);
        return wrapper;
    }
}
exports.ComplexModal = ComplexModal;
