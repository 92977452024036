"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModalManager = void 0;
const modal_state_1 = require("../enums/modal-state");
class ModalManager {
    constructor() {
        this.modalStack = [];
        this.documentBody = document.querySelector("body");
    }
    open(modal) {
        // If the modal was already open; return to prevent double triggering
        if (modal.getState() === modal_state_1.ModalState.OPEN
            || modal.getState() === modal_state_1.ModalState.VISIBLE) {
            return;
        }
        if (this.modalStack.length > 0) {
            this.hideActiveModals();
        }
        this.disableBodyScroll();
        this.modalStack.unshift(modal);
        switch (modal.getState()) {
            case modal_state_1.ModalState.DESTROYED:
            case modal_state_1.ModalState.CREATED:
                modal.setState(modal_state_1.ModalState.OPEN);
                this.injectModal(modal);
                break;
            case modal_state_1.ModalState.CLOSED:
                modal.setState(modal_state_1.ModalState.VISIBLE);
                break;
        }
    }
    close(modal) {
        this.modalStack.splice(this.modalStack.indexOf(modal), 1);
        modal.setState(modal_state_1.ModalState.CLOSED);
        this.uncoverTopOfStack();
        if (modal.shouldDestroyOnClose()) {
            this.destroyModal(modal);
        }
    }
    injectModal(modal) {
        const body = document.querySelector("body");
        if (body !== null) {
            body.appendChild(modal.getTemplate());
            modal.setState(modal_state_1.ModalState.VISIBLE);
        }
    }
    destroyModal(modal) {
        const modalInstance = document.querySelector('[data-identifier="' + modal.getIdentifier() + '"]');
        if (modalInstance !== null) {
            setTimeout(() => {
                this.removeModalFromDOM(modal, modalInstance);
            }, 200);
        }
    }
    removeModalFromDOM(modal, modalElement) {
        if (modalElement.parentNode === null) {
            return;
        }
        modalElement.parentNode.removeChild(modalElement);
        modal.setState(modal_state_1.ModalState.DESTROYED);
    }
    hideActiveModals() {
        this.modalStack.forEach((modal) => {
            modal.setState(modal_state_1.ModalState.OPEN);
        });
    }
    uncoverTopOfStack() {
        if (this.modalStack.length > 0) {
            this.modalStack[0].setState(modal_state_1.ModalState.VISIBLE);
            return;
        }
        this.enableBodyScroll();
    }
    disableBodyScroll() {
        if (this.documentBody !== null) {
            this.documentBody.setAttribute("data-modal-opened", "true");
        }
    }
    enableBodyScroll() {
        if (this.documentBody !== null) {
            this.documentBody.setAttribute("data-modal-opened", "false");
        }
    }
}
exports.ModalManager = ModalManager;
