"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Modal = void 0;
const modal_state_1 = require("../enums/modal-state");
class Modal {
    constructor(content, modalConfig) {
        this.state = modal_state_1.ModalState.CREATED;
        this.identifier = this.generateIdentifier();
        this.content = content;
        this.destroyOnClose = modalConfig.destroyOnClose;
        this.onOpenHandler = modalConfig.onOpen;
        this.onCloseHandler = modalConfig.onClose;
        this.cssClassName = modalConfig.cssClassName;
    }
    getTemplate() {
        const wrapper = this.generateOverlay();
        const modal = this.generateModal();
        wrapper.appendChild(modal);
        return wrapper;
    }
    getIdentifier() {
        return this.identifier;
    }
    getState() {
        return this.state;
    }
    setState(state) {
        this.triggerHandlers(state);
        this.setStateClass(state);
        this.state = state;
    }
    shouldDestroyOnClose() {
        return this.destroyOnClose;
    }
    generateOverlay() {
        const wrapper = this.createElement("div", ["ModalOverlay"]);
        wrapper.setAttribute("data-identifier", this.identifier);
        wrapper.setAttribute("aria-modal", "true");
        wrapper.setAttribute("tabindex", "-1");
        wrapper.setAttribute("role", "dialog");
        wrapper.addEventListener("click", this.closeModal.bind(this));
        return wrapper;
    }
    generateModal() {
        const modal = this.createElement("div", ["ModalWrapper"]);
        // Prevent event propagation on the modal itself
        modal.addEventListener("click", (e) => e.stopPropagation());
        const contentWrapper = this.createElement("div", ["ContentWrapper"]);
        if (this.cssClassName) {
            modal.classList.add(this.cssClassName);
        }
        const closeButton = this.createElement("button", ["CloseButton"], '<i class="Icon lnr-cross" />');
        closeButton.addEventListener("click", this.closeModal.bind(this));
        modal.appendChild(closeButton);
        contentWrapper.appendChild(this.content);
        modal.appendChild(contentWrapper);
        return modal;
    }
    createElement(type, classNames, html) {
        const element = document.createElement(type);
        if (classNames && classNames.length > 0) {
            classNames.forEach(name => element.classList.add(name));
        }
        if (html) {
            element.innerHTML = html;
        }
        return element;
    }
    getModalFromDOM() {
        return document.querySelector(`[data-identifier=${this.identifier}]`);
    }
    triggerHandlers(state) {
        if (state === modal_state_1.ModalState.CLOSED && this.onCloseHandler) {
            this.onCloseHandler();
        }
        if (state === modal_state_1.ModalState.OPEN && this.onOpenHandler) {
            this.onOpenHandler();
        }
    }
    setStateClass(state) {
        const modalWrapper = this.getModalFromDOM();
        if (!modalWrapper) {
            return null;
        }
        for (const possibleState in modal_state_1.ModalState) {
            modalWrapper.classList.remove(`is-${modal_state_1.ModalState[possibleState]}`);
        }
        modalWrapper.classList.add(`is-${state}`);
        this.hideModalAfterAnimation(modalWrapper, state);
    }
    generateIdentifier() {
        let identifier = "";
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        for (let i = 0; i < 10; i++) {
            identifier += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return identifier;
    }
    hideModalAfterAnimation(modal, animatedState) {
        modal.classList.remove("Hidden");
        if (animatedState === modal_state_1.ModalState.CLOSED) {
            setTimeout(() => {
                modal.classList.add("Hidden");
            }, 200);
            return;
        }
    }
    closeModal(event) {
        event.stopPropagation();
        if (typeof window["ModalManager"] === "undefined") {
            return null;
        }
        window["ModalManager"].close(this);
    }
}
exports.Modal = Modal;
