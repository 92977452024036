"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SimpleModal = void 0;
const modal_1 = require("./modal");
class SimpleModal extends modal_1.Modal {
    constructor(content, modalConfig, cta = null) {
        super(content, modalConfig);
        this.cta = cta;
        if (this.cta !== null) {
            window.addEventListener("resize", this.stickyCTA.bind(this));
        }
    }
    getTemplate() {
        const wrapper = this.generateOverlay();
        const modal = this.generateModal();
        modal.classList.add("SimpleModal");
        if (this.cta !== null) {
            const ctaWrapper = this.createElement("div", ["CTAWrapper"]);
            ctaWrapper.appendChild(this.cta);
            modal.appendChild(ctaWrapper);
        }
        wrapper.appendChild(modal);
        return wrapper;
    }
    setState(state) {
        this.triggerHandlers(state);
        this.setStateClass(state);
        this.stickyCTA();
        this.state = state;
    }
    stickyCTA() {
        const modal = this.getModalFromDOM();
        if (modal === null) {
            return null;
        }
        const modalWrapper = modal.querySelector(".ModalWrapper");
        const ctaWrapper = modal.querySelector(".CTAWrapper");
        const contentWrapper = modal.querySelector(".ContentWrapper");
        if (ctaWrapper === null
            || contentWrapper === null
            || modalWrapper === null) {
            return null;
        }
        this.setContentDimensions(contentWrapper, modalWrapper, ctaWrapper);
    }
    setContentDimensions(contentWrapper, modalWrapper, ctaWrapper) {
        const wrapperStyles = window.getComputedStyle(modalWrapper);
        const wrapperHeight = wrapperStyles.getPropertyValue("height");
        if (window.innerWidth < 769) {
            contentWrapper.style.height = "auto";
            contentWrapper.style.paddingBottom = `calc(1.5rem + ${ctaWrapper.offsetHeight}px)`;
            this.checkOverflow(contentWrapper, modalWrapper);
            return;
        }
        contentWrapper.style.height = `calc(${wrapperHeight} - ${ctaWrapper.offsetHeight}px)`;
        contentWrapper.style.paddingBottom = "3rem";
        this.checkOverflow(contentWrapper, modalWrapper);
    }
    checkOverflow(element, modalWrapper) {
        if (element.scrollHeight > element.offsetHeight) {
            modalWrapper.classList.add("is-overflowing");
            return;
        }
        modalWrapper.classList.remove("is-overflowing");
    }
}
exports.SimpleModal = SimpleModal;
